import { type AxiosRequestConfig } from "axios";
import { useMsal } from "@azure/msal-react";

import { MediaType } from "../openapi/api";
import { Configuration, DatasetApi, type DatasetCreate } from "../openapi";

export const useApi = () => {
  const { instance } = useMsal();

  const config = new Configuration({
    basePath: `${window.REACT_APP_MEDIA_DATA_URL}`,
  });

  const datasetApi = new DatasetApi(config);

  const getToken = async () => {
    let token;
    let tokenError;
    const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;

    try {
      const response = await instance.acquireTokenSilent({
        scopes: [`api://${tenantAppId}/.default`],
      });
      token = response.accessToken;
    } catch (error) {
      tokenError = error;
    }

    return token;
  };

  // TODO try interceptor to change url?
  const getOptions = async (): Promise<AxiosRequestConfig> => {
    const token = await getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const createDataset = async (datasetName: string) => {
    const dataset: DatasetCreate = {
      name: datasetName,
    };

    const additionalOptions = await getOptions();
    const response = await datasetApi.createDataset(dataset, additionalOptions);
    return response;
  };

  const createFile = async (
    datasetId: string,
    name: string,
    type: MediaType,
    file: File,
  ) => {
    const additionalOptions = await getOptions();
    const response = await datasetApi.createFile(
      datasetId,
      name,
      type,
      file,
      undefined,
      additionalOptions,
    );
    return response;
  };

  const findFilesByDatasetId = async (
    datasetId: string,
    type: MediaType | undefined = undefined,
  ) => {
    const additionalOptions = await getOptions();
    const response = await datasetApi.findFilesByDatasetId(
      datasetId,
      type,
      additionalOptions,
    );
    return response;
  };

  const finishDataset = async (datasetId: string) => {
    const additionalOptions = await getOptions();
    const response = await datasetApi.finishDataset(
      datasetId,
      additionalOptions,
    );
    return response;
  };

  return {
    createDataset,
    createFile,
    findFilesByDatasetId,
    finishDataset,
  };
};
