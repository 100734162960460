import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  LinearProgress,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";

import DragAndDrop, { ZipStatus } from "../components/uploader//DragAndDrop";
import { useApi } from "../hooks/useApi";
import IconTooltip from "../components/uploader/IconTooltip";
import InfoSnackbar from "../components/uploader/InfoSnackbar";

import { FormField, UploadedFile, UploadParams } from "../types";
import {
  Folder,
  Notes,
  AddLink,
  UploadFile,
  ModeEdit,
} from "@mui/icons-material";

import { CDN_IMAGES } from "../util/images";
import { humanFileSize } from "../util/utils";
import { isTestingWithJest } from "../util/testing";
import UploadSuccessDialog from "../components/uploader/UploadSuccessDialog";
import EditDatasetNameDialog from "../components/uploader/DatasetNameDialog";
import DatasetNameDialog from "../components/uploader/DatasetNameDialog";
import { MediaType } from "../openapi";

interface UploadViewProps {
  isOnline: boolean;
  getKID: () => string;
}

const RETRY_TIMEOUT_MS = 2000;

export const UploadView = (props: UploadViewProps) => {
  const { createDataset, createFile, findFilesByDatasetId, finishDataset } =
    useApi();
  // const chunkSize = 2 * (1024 * 1024); // 2MB
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [comment, setComment] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  // const [datasetName, setDatasetName] = useState<string>("");
  const [showDatesetNameDialog, setShowDatesetNameDialog] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  // const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [uploadingFile, setUploadingFile] = useState<File | undefined>(
    undefined,
  );
  // const [zipStats, setZipStats] = useState<ZipStatus>({
  //   percentage: 50,
  //   datasetName: "",
  //   inProgress: false,
  // });

  const [uploadParams, setUploadParams] = useState<UploadParams>({
    chunkIndex: 0,
    startRange: 0,
    stopRange: 0,
    buffer: 1,
    processId: "",
  });

  const [formData, setFormData] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const [formErrors, setFormErrors] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, comment: event.target.value });
  };

  const handleReferenceChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, reference: event.target.value });
  };

  const handleDeleteFile = (fileId: string | undefined) => {
    if (fileId) {
      setUploadedFiles((files) => files.filter((file) => file.id !== fileId));

      if (uploadedFiles.length == 0) {
        setFormData({ ...formData, datasetName: "" });
      }
    } else {
      setUploadedFiles([]);
      setFormData({ ...formData, datasetName: "" });
    }
  };

  const validateForm = () => {
    const newFormErrors: Record<FormField, string> = {
      file: "",
      datasetName: "",
      reference: "",
      comment: "",
    };

    if (comment && comment.length > 100) {
      newFormErrors.reference =
        "Dataset comment cannot be longer than 100 characters";
    }

    if (reference && reference.length > 100) {
      newFormErrors.reference =
        "Dataset reference cannot be longer than 20 characters";
    }

    if (!formData.datasetName) {
      newFormErrors.datasetName = "Dataset name is required";
    }

    if (
      formData.datasetName &&
      !/^[a-zA-Z0-9_.-]{0,512}$/.test(formData.datasetName)
    ) {
      newFormErrors.datasetName = "No special or more than 50 characters.";
    }

    if (uploadedFiles.length == 0) {
      newFormErrors.file = "You need to select a file.";
    }

    // if (currentFile !== undefined && currentFile.type !== "application/zip") {
    //   newFormErrors.file = "The uploaded file needs to be zip file";
    // }

    // check if uploaded zip file contains a dir
    // if (currentFile !== undefined) {
    //   JSZip.loadAsync(currentFile).then(
    //     (zip) => {
    //       zip.forEach((_relativePath: string, zipEntry: { dir: boolean }) => {
    //         if (zipEntry.dir) {
    //           newFormErrors.file =
    //             "Found directory in zip file, can not upload";
    //         }
    //       });
    //     },
    //     (error) => {
    //       if (!isTestingWithJest()) {
    //         // eslint-disable-next-line no-console
    //         console.log(`zip error: ${error}`);
    //       }
    //     },
    //   );
    // }

    setFormErrors(newFormErrors);

    // return true if one value in newFormErrors is !== ""
    return !(Object.keys(newFormErrors) as Array<FormField>).some(
      (key) => newFormErrors[key] !== "",
    );
  };

  const uploadCleanup = () => {
    setIsUploading(false);
    setUploadSuccess(true);

    const successSnackbarTime = setInterval(() => {
      // setCurrentFile(undefined);
      setUploadParams({
        chunkIndex: 0,
        startRange: 0,
        stopRange: 0,
        buffer: 1,
        processId: "",
      });
      clearInterval(successSnackbarTime);
    }, 5000);
  };

  const uploadFiles = async () => {
    // TODO set is uploading
    // TODO add is Offline logik
    const response = await createDataset(formData.datasetName);
    const datasetId = response.data["id"];
    // console.log("dataset create: "+ JSON.stringify(response))
    console.log("dataset id: " + datasetId);

    await Promise.all(
      uploadedFiles.map(async (uploadedFile) => {
        createFile(
          datasetId,
          uploadedFile.file.name,
          MediaType.DroneImage,
          uploadedFile.file,
        );
        console.log("creating file ");
      }),
    ).finally(async () => {
      const intervallID = setInterval(async () => {
        const response = await findFilesByDatasetId(datasetId);
        if (response.data.count == uploadedFiles.length) {
          await finishDataset(datasetId);
          clearInterval(intervallID);
        }
      }, RETRY_TIMEOUT_MS);
      // const finsishResponse = await finishDataset(datasetId);
      // console.log("dataset finish: " + JSON.stringify(finsishResponse, null, 2));
    });

    // uploadedFiles.forEach(async (uploadedFile) => {
    //   const response = await createFile(
    //     datasetId,
    //     uploadedFile.file.name,
    //     MediaType.DroneImage,
    //     uploadedFile.file,
    //   );
    //   // TODO set loading circle for each file? and finished
    //    console.log("file created: " + JSON.stringify(response.data["id"]));
    // });

    // console.log("uploaded files count beofre "+uploadedFiles.length);

    // await Promise.all(files.map(async (file) => {
    //   const contents = await fs.readFile(file, 'utf8')
    //   console.log(contents)
    // })).;

    // // wait for all files to be uploaded
    // for (let i = 0; i < MAX_RETRIES; i++) {
    //   setTimeout(async () => {
    //     const findResponse = await findFilesByDatasetId(datasetId);
    //     // console.log("retry find files "+JSON.stringify(findResponse, null, 2));
    //     console.log("find files count"+findResponse.data.count);
    //     console.log("uploaded files count"+uploadedFiles.length);

    //     if (findResponse.data.count == uploadedFiles.length) {
    //       console.log("uploaded all items, finishing dataset");

    //       const finsishResponse = await finishDataset(datasetId);
    //       console.log("dataset finish: " + JSON.stringify(finsishResponse, null, 2));
    //       return
    //     }
    //   }, RETRY_TIMEOUT_MS);
    // }

    // console.log("error! couldn't upload all ");
  };

  useEffect(() => {
    if (props.isOnline && isUploading) {
      uploadFiles();
    }
  }, [props.isOnline]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      // scroll to bottom of page, so user sees the progressbar
      window.scrollTo(0, document.body.scrollHeight);
      uploadFiles();
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));
    event.stopPropagation();
    event.preventDefault();

    const entry = event.dataTransfer.items[0].webkitGetAsEntry();

    if (!entry) return;

    if (entry.isFile) {
      (entry as FileSystemFileEntry).file((file) => {
        if (file.type != "image/jpeg") {
          setFormErrors((errors) => ({
            ...errors,
            file: "Only jpeg files and folder with only images are allowed.",
          }));
          return;
        }
        const newUploadedFile: UploadedFile = {
          id: crypto.randomUUID(),
          file: file,
        };
        setUploadedFiles([...uploadedFiles, newUploadedFile]);
        // console.log("entry file " + file);
      });
    } else if (entry.isDirectory) {
      // console.log("is a folder "+ entry.name);

      setFormData({ ...formData, datasetName: entry.name });

      const tmpFiles: UploadedFile[] = [];
      const reader = (entry as FileSystemDirectoryEntry).createReader();

      reader.readEntries(function (entries) {
        const totalFiles = entries.length;
        let counter = 0;

        entries.forEach((dir) => {
          (dir as FileSystemFileEntry).file((file) => {
            counter++;

            if (file.type != "image/jpeg") {
              setFormErrors((errors) => ({
                ...errors,
                file: "Only jpeg files and folder with only images are allowed.",
              }));
              return;
            }

            const newUploadedFile: UploadedFile = {
              id: crypto.randomUUID(),
              file: file,
            };

            tmpFiles.push(newUploadedFile);

            if (totalFiles == counter) {
              setUploadedFiles([...uploadedFiles, ...tmpFiles]);
            }
          });

          // console.log("fileeees: " + tmpFiles);
          // const newUploadedFiles: File[] = [...uploadedFiles, ...tmpFiles];
          // setUploadedFiles(newUploadedFiles);
        });
      });
    }
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files == null) return;

    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));

    if (event.target.files.length > 1) {
      console.log(
        "dataset anme: " +
          event.target.files[0].webkitRelativePath.split("/")[0],
      );
      setFormData({
        ...formData,
        datasetName: event.target.files[0].webkitRelativePath.split("/")[0],
      });
      const tmpFiles: UploadedFile[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const newUploadedFile: UploadedFile = {
          id: crypto.randomUUID(),
          file: event.target.files[i],
        };

        tmpFiles.push(newUploadedFile);
      }
      setUploadedFiles([...uploadedFiles, ...tmpFiles]);
    } else {
      const newUploadedFile: UploadedFile = {
        id: crypto.randomUUID(),
        file: event.target.files[0],
      };
      setUploadedFiles([...uploadedFiles, newUploadedFile]);
    }

    // setCurrentFile(event.target.files[0]);
    // setFormData({
    //   ...formData,
    //   datasetName: event.target.files[0].name.split(".")[0] || "images",
    // });
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        paddingY="3em"
        gap={4}
      >
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Box width="100px">
            <img
              src={CDN_IMAGES.logos("media_data_logos/md_uploader.svg")}
              height="100%"
              width="auto"
              alt="mdp-viewer"
            />
          </Box>
          <Typography variant="h4" fontWeight="bold">
            Welcome to Media Data Uploader
          </Typography>
          {/* <Typography variant="subtitle1">
            Bring your Media Data to iPEN
          </Typography> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ width: "500px" }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Box display="flex" alignItems="start" gap={1} width={1}>
              <TextField
                id="dataset-textfield"
                fullWidth
                placeholder="Dataset name"
                disabled
                aria-label="Dataset Textfield"
                value={formData.datasetName}
                helperText={formErrors.datasetName}
                error={formErrors.datasetName != ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Folder />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          // setProcessIntermediateName(formData.process);
                          setShowDatesetNameDialog(true);
                        }}
                      >
                        <ModeEdit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconTooltip text="Enter your Dataset Name to identify the uploaded file" />
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="comment-textfield"
                  fullWidth
                  placeholder="Dataset comment"
                  aria-label="Comment Textfield"
                  value={formData.comment}
                  helperText={formErrors.comment}
                  error={formErrors.comment != ""}
                  onChange={handleCommentChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Notes />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a comment for the Dataset for additional information." />
              </Box>
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="reference-textfield"
                  fullWidth
                  placeholder="Dataset reference"
                  aria-label="Reference Textfield"
                  value={formData.reference}
                  helperText={formErrors.reference}
                  error={formErrors.reference != ""}
                  onChange={handleReferenceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLink />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a reference for the Dataset." />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width={1}
              flexDirection="column"
            >
              <DragAndDrop
                dragAndDropAriaLabel="Drag and Drop field to upload Media Data"
                uploadedFiles={uploadedFiles}
                resetFile={() => {
                  // setCurrentFile(undefined);
                }}
                zipStats={null}
                handleFileSelect={handleFileSelect}
                handleDrop={handleDrop}
                handleDeleteFile={handleDeleteFile}
                helperText={formErrors.file}
                hasError={formErrors.file != ""}
                isUploading={isUploading}
                datasetName={formData.datasetName}
              />
            </Box>

            {uploadingFile && (
              <Box
                display="flex"
                width={1}
                alignItems="center"
                border={1}
                py={2}
                borderRadius={1}
                borderColor={isUploading ? "secondary.main" : "success.main"}
              >
                <UploadFile
                  color={isUploading ? "secondary" : "success"}
                  sx={{ p: 2 }}
                />
                <Box display="flex" flexDirection="column" width={1} gap="3px">
                  <Typography variant="body1">{uploadingFile?.name}</Typography>
                  <Typography variant="body2" color="gray" component="div">
                    {humanFileSize(uploadingFile?.size || 0)} •{" "}
                    {isUploading ? (
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        pl={1}
                        gap={1}
                      >
                        <CircularProgress
                          size={12}
                          color="secondary"
                          data-testid="upload-loading"
                        />{" "}
                        Loading
                      </Box>
                    ) : (
                      "Complete"
                    )}
                  </Typography>
                  <LinearProgress
                    sx={{ width: "50%", borderRadius: 2, opacity: 0.7 }}
                    variant="determinate"
                    color={isUploading ? "secondary" : "success"}
                    value={progress}
                    aria-label="Progressbar to display the upload progress"
                  />
                </Box>
              </Box>
            )}

            <Button
              type="submit"
              data-testid="submit"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isUploading}
              startIcon={<CloudUploadOutlined />}
            >
              Upload
            </Button>
          </Box>
        </form>
      </Grid>

      <InfoSnackbar
        open={!props.isOnline}
        message={
          !props.isOnline && isUploading
            ? `Internet connection lost - Failed to upload chunk ${uploadParams.chunkIndex}`
            : "Internet connection lost"
        }
        severity="warning"
        hideDuration={0}
      />

      <UploadSuccessDialog
        uploadSuccess={uploadSuccess}
        processIntermediateName={formData.datasetName}
        setUploadSuccess={setUploadSuccess}
      />

      <DatasetNameDialog
        showDatasetNameDialog={showDatesetNameDialog}
        // datasetIntermediateName={processIntermediateName}
        formData={formData}
        setShowDatasetNameDialog={setShowDatesetNameDialog}
        // setProcessIntermediateName={setProcessIntermediateName}
        setFormData={setFormData}
      />
    </>
  );
};
